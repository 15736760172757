<template>
  <div>
    <div class="text-center p-3" v-if="loading">
      <b-spinner variant="primary" label="Loading..."></b-spinner>
    </div>
    <div class="mb-4" v-if="!loading && explorer">
      <div class="asset">
        <icon-crypto :coinname="explorer.symbol" format="svg" />
        <div>
          <h5>{{ explorer.name }}</h5>
          <h6>{{ explorer.symbol }}</h6>
        </div>
      </div>

      <div class="main-card mb-4">
        <div class="d-flex justify-content-between align-items-center mb-5">
          <h6 class="overview-title">{{ $t("EXPLORER.OVERVIEW") }}</h6>
        </div>
        <div class="mb-5" a-tag="explorerButton">
          <Button
            v-for="(item, index) in chartButtons"
            :key="index"
            customClass="
            chart-btn
          "
            :color="interval === item.value ? 'light-primary' : 'light-dark'"
            @click="handleChangeChart(item.value)"
          >
            {{ item.title }}
          </Button>
        </div>

        <div class="text-center p-3" v-if="chartloading">
          <b-spinner variant="primary" label="Loading..."></b-spinner>
        </div>
        <div v-else-if="!chartLoading && chartData">
          <line-chart
            v-if="chartData"
            :chartdata="chartData"
            :options="options"
            a-tag="explorerChart"
          />
        </div>
      </div>
      <div class="main-card mb-4">
        <div class="d-flex justify-content-between align-items-center mb-5">
          <h6 class="overview-title">{{ $t("EXPLORER.ADDITIONAL_STATS") }}</h6>
        </div>
        <div class="row" v-if="explorer">
          <div class="col-md-6">
            <stats
              class="border-bottom"
              :title="$t('EXPLORER.MARKET_CAP')"
              :value="explorer.market_cap"
            />
          </div>
          <div class="col-md-6">
            <stats
              class="border-bottom"
              :title="$t('EXPLORER.CIRCULATING_SUPPLY')"
              :value="explorer.circulating_supply"
            />
          </div>
          <div class="col-md-6">
            <stats
              class="border-bottom"
              :title="$t('EXPLORER.TRADING_VOL')"
              :value="explorer.volume_24h"
            />
          </div>
          <div class="col-md-6">
            <stats
              class="border-bottom"
              :title="$t('EXPLORER.TOTAL_SUPPLY')"
              :value="explorer.total_supply"
            />
          </div>
          <div class="col-md-6">
            <stats
              :title="$t('EXPLORER.FULLY_DILUTED')"
              :value="explorer.fully_diluted_market_cap"
            />
          </div>
          <div class="col-md-6">
            <stats :title="$t('EXPLORER.MAX_SUPPLY')" :value="explorer.max_supply" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getExplorerInfoRequest, getExplorerChartRequest } from "@/api/explorer";
import LineChart from "@/components/Application/Chart/LineChart";
import Stats from "@/components/Application/Explorer/Stats";
import { getDateFormat } from "@/helpers";

export default {
  components: {
    LineChart,
    Stats,
  },
  data() {
    return {
      explorer: null,
      explorerId: this.$route.params.id,
      loading: false,
      chartloading: false,
      interval: "hourly",
      chartData: null,
      chartButtons: [
        {
          title: this.$t("EXPLORER.HOURLY"),
          value: "hourly",
        },
        {
          title: this.$t("EXPLORER.DAILY"),
          value: "daily",
        },
        {
          title: this.$t("EXPLORER.WEEKLY"),
          value: "weekly",
        },
      ],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      },
    };
  },
  created() {
    this.handleLoadExplorer();
  },

  methods: {
    handleLoadExplorer() {
      const params = {
        id: this.explorerId,
      };
      this.loading = true;
      this.ApiService(getExplorerInfoRequest(params))
        .then(({ data }) => {
          this.explorer = data.data;
          this.handleLoadChartExplorer(data.data.symbol);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleLoadChartExplorer(symbol) {
      const params = {
        symbol,
        interval: this.interval,
      };
      this.chartloading = true;
      this.ApiService(getExplorerChartRequest(params))
        .then(({ data }) => {
          let format = "DD/MM";
          switch (this.interval) {
            case "hourly":
              format = "hh:ss";
              break;

            case "daily":
              format = "DD/MM";
              break;

            case "weekly":
              format = "DD/MM";
              break;

            default:
              break;
          }
          let chartLabels = data.data?.map((chart) =>
            getDateFormat(new Date(chart.timestamp), format)
          );
          let chartValue = data.data?.map((chart) => chart.price);

          this.chartData = {
            labels: chartLabels,
            datasets: [
              {
                backgroundColor: "#EFDFFF80",
                data: chartValue,
                borderColor: "#532680",
                pointBackgroundColor: "#532680",
              },
            ],
          };
        })
        .finally(() => {
          this.chartloading = false;
        });
    },
    handleChangeChart(value) {
      this.interval = value;
      this.handleLoadChartExplorer(this.explorer.symbol);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
