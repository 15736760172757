<template>
  <div class="stats-container">
    <div>
      <h6 class="title">{{ title }}</h6>
      <h4 class="value">{{ value ? `$${handleRoundNumber(value)}` : 0 }}</h4>
    </div>
  </div>
</template>

<script>
import { handleRoundNumber } from "@/helpers";

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      handleRoundNumber,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
