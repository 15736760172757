export const getExplorerRequest = (params) => ({
  method: "get",
  url: "exchange/coin-market-list",
  config: {
    params,
  },
});

export const getExplorerInfoRequest = (params) => ({
  method: "get",
  url: `exchange/coin-market-additional-stats/${params.id}`,
});

export const getExplorerChartRequest = (params) => ({
  method: "get",
  url: `exchange/coin-market-historical-list/${params.symbol}`,
  config: {
    params,
  },
});
